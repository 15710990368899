<template>
  <a-row>
    <a-col :sm="24" :xs="24">
      <a-row class="mt-1" style="padding-right: 8px;" gutter="8">
        <a-col
          :lg="4"
          :md="6"
          :xs="20"
          v-if="
            authStore.config?.bench_filtrable_columns?.general?.region_country
          "
        >
          <a-select
            v-model:value="benchmarkStore.data.region_id"
            style="width: 100%; height: 45px;"
            :placeholder="$t('search_benchmarks.asset_region')"
            @change="regionCategoryChanged"
            class="native-select modal-select"
            :loading="benchmarkStore.loadingRegions"
            show-search
            allowClear
            :options="regionsList"
            :filter-option="filterOption"
          ></a-select>
        </a-col>
        <a-col
          :lg="4"
          :md="6"
          :xs="20"
          v-if="authStore.config?.bench_filtrable_columns?.general?.category"
        >
          <a-select
            v-model:value="benchmarkStore.data.category"
            style="width: 100%; height: 45px;"
            :placeholder="$t('search_benchmarks.asset_category')"
            @change="regionCategoryChanged"
            class="native-select modal-select"
            :loading="benchmarkStore.loadingCategories"
            show-search
            allowClear
            :options="categoriesList"
            :filter-option="filterOption"
          ></a-select>
        </a-col>
        <a-col :span="8" :md="8" :xs="20">
          <a-select
            mode="multiple"
            v-model:value="benchmarks"
            style="width: 100%; height: 45px !important;"
            :placeholder="
              benchmarkStore.loadingBenchmarks
                ? $t('generic.buttons.loading')
                : (benchmarkStore.data.region_id ||
                    !authStore.config?.bench_filtrable_columns?.general
                      ?.region_country) &&
                  (benchmarkStore.data.category ||
                    !authStore.config?.bench_filtrable_columns?.general
                      ?.category)
                ? $t('search_benchmarks.asset_dropdown_placeholder')
                : $t('search_benchmarks.asset_region_category')
            "
            class="native-select modal-select"
            :class="{ 'h-100': benchmarks.length }"
            :loading="benchmarkStore.loadingBenchmarks"
            :max-tag-count="1"
          >
            <a-select-option
              v-for="item in benchmarkStore.benchmarkSelectedList"
              :value="item.isin_tkr_id"
              :key="item.isin_tkr_id + 'm'"
              :title="`${item.isin_tkr} | ${item.name}`"
              >{{ item.isin_tkr }} | {{ item.name }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col
          :xs="24"
          class="mt-2"
        >
          <sdButton
            type="secondary"
            :disabled="!benchmarks.length"
            :loading="isLoading"
            @click="handleOk"
            style="width: 10rem;"
          >
            {{ $t("generic.buttons.add_to_basket") }}
          </sdButton>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script setup>
import { useBenchmarkStore } from "@/stores/BenchmarkStore";
import { useBasketStore } from "@/stores/BasketStore";
import { useAuthStore } from "@/stores/AuthStore";
import { ref, onMounted, computed } from "vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const benchmarkStore = useBenchmarkStore();
const basketStore = useBasketStore();
const authStore = useAuthStore();
const { t } = useI18n();
const benchmarks = ref([]);
const isLoading = ref(false);

const regionsList = computed(() =>
  benchmarkStore.regions.map((region) => ({
    value: region.id,
    label: t(`regions.${region.cat_name}`, region.cat_name),
  }))
);
const categoriesList = computed(() =>
  benchmarkStore.categories.map((category) => ({
    value: category,
    label: t(`benchmark_categories.${category}`, category),
  }))
);

const handleOk = async () => {
  isLoading.value = true;
  try {
    const result = await benchmarkStore.getBenchmarksByTkr(benchmarks.value);
    if (result && Array.isArray(result)) {
      await basketStore.addAsset(result);
      message.success(t("funds_printer.added_benchmarks"));
    }
  } catch (err) {
    console.log(err);
  } finally {
    isLoading.value = false;
  }
  benchmarks.value = [];
};
const regionCategoryChanged = async () => {
  if (
    (benchmarkStore.data.region_id ||
      !authStore.config?.bench_filtrable_columns?.general?.region_country) &&
    (benchmarkStore.data.category ||
      !authStore.config?.bench_filtrable_columns?.general?.category)
  ) {
    try {
      await benchmarkStore.searchBenchmarks();
    } catch (err) {
      console.log(err);
    }
  }
};
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
onMounted(async () => {
  benchmarkStore.getRegions();
  benchmarkStore.getCategories();
});
</script>
<style scoped lang="scss">
:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }
  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
  & .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}
:deep() {
  .aling-self-center {
    align-self: center;
  }
}
</style>
