<template>
  <div>
    <Main class="general-background">
      <sdCards headless style="margin-bottom: 7.5rem;" class="search-card">
        <a-row>
          <a-col :xs="24" :md="16" class="search-tabs">
            <p
              class="search-tab-item"
              v-for="item in searchTabs"
              :key="item.id"
              @click="changeSearchTab(item.id)"
              :class="{ 'active': activeSearch === item.id, 'border-r': item.id === SearchCardItems.SEARCH_INDEX_NAME }"
            >{{ $t(item.label) }}</p>
          </a-col>  
          <a-col :xs="16" v-if="activeSearch === SearchCardItems.SEARCH_INDEX_NAME">
            <asset-by-ticker
              @updatingTkrs="updateTkrList"
              :tickerType="'benchmarks'"
              @blur="resetTicker"
              v-model="isinTkr"
              @selectedSearch="searchBenchmarksByTkr"
              :loading="isLoading"
              :showTitle="false"
              :auto-filter="false"
            >
            </asset-by-ticker>
          </a-col>
          <a-col :xs="24" v-if="activeSearch === SearchCardItems.SEARHC_INDEX_CUSTOM">
            <filter-benchmarks></filter-benchmarks>
          </a-col>
        </a-row>
      </sdCards>
      <basket-card assetType="master_others_columns" ref="fundsTable">
      </basket-card>
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { ref, onMounted, watch } from "vue";
import { useBenchmarkStore } from "@/stores/BenchmarkStore";
import AssetByTicker from "@/components/wiseral/searchAnalysis/AssetByTicker.vue";
import BasketCard from "@/components/wiseral/basket/BasketCard.vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import FilterBenchmarks from "@/components/wiseral/benchmarks/FilterBenchmarks.vue";
import { useBasketStore } from "@/stores/BasketStore";
import { useRouter } from "vue-router";
import SearchCardItems from "@/config/search/cardIDs";

const basketStore = useBasketStore();

const { t } = useI18n();

const searchTabs = [
  {
    id: SearchCardItems.SEARCH_INDEX_NAME,
    label: 'search_funds.search_asset'
  },
  {
    id: SearchCardItems.SEARHC_INDEX_CUSTOM,
    label: 'search_funds.select_filter_criteria'
  }
];

const activeSearch = ref(SearchCardItems.SEARCH_INDEX_NAME);

const router = useRouter();
const benchmarkStore = useBenchmarkStore();
const isinTkr = ref([]);
const isLoading = ref(false);
const selectedTkrs = ref([]);

const changeSearchTab = (tabKey) => {
  activeSearch.value = tabKey;
}

const resetTicker = () => {
  isinTkr.value = benchmarkStore.data.isin;
};

watch(
  () => isinTkr.value,
  () => {
    if (Array.isArray(isinTkr.value)) {
      benchmarkStore.data.isin = isinTkr.value.filter(
        (e) => typeof e === "number"
      );
    }
  }
);

onMounted(async () => {
  const path = router.currentRoute.value.path;
  await basketStore.changeBasketUrl(path);
});

const updateTkrList = (e) => {
  selectedTkrs.value = e.id;
};

const searchBenchmarksByTkr = async () => {
  isLoading.value = true;

  try {
    const result = await benchmarkStore.getBenchmarksByTkr(selectedTkrs.value);
    if (result && Array.isArray(result)) {
      await basketStore.addAsset(result);
      message.success(t("funds_printer.added_benchmarks"));
    }
  } catch (err) {
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};
</script>
<style scoped lang="scss">
.general-background {
  background-color: #F2F2F2;
}
:deep(.native-select.modal-select) {
  &.ant-select-multiple {
    padding: 0px !important;
    margin: 0px !important;
    height: 38px !important;
    overflow: hidden;
  }
  & .ant-select-selector {
    border-color: #000 !important;
  }
  .ant-select-arrow {
    margin-right: 10px;
  }
}
.search-card {
  border: 1px solid #02B388;
  background-color: transparent;
}
.search-tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
  & .search-tab-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0 !important;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 14px;
    &.active {
      background-color: #004851;
      color: white;
    }
    &:hover {
      background-color: #004851;
      color: white;
    }
    &.border-r {
      border-right: 1px solid black;
    }
  }
}
</style>
